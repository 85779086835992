
import Vue from 'vue'
import Links from './Links.vue'

interface PuffProps {
	title: string,
	text: string,
	bgColor: string,
	textColor: string,
	image: string,
}

export default Vue.extend<{}, {}, {}, PuffProps>({
	name: 'TopPuff',
	computed: {
		title() { return this.$store.getters.title},
        text() { return this.$store.getters.text},
		bgColor() { return this.$store.getters.bgColor},
		textColor() { 
			var color = this.$store.getters.textColor;
			if(!color || color.length === 0 ){
				color = "#fff";
			}
			return color;
		},
		buttonColor() { return this.$store.getters.buttonColor},
		buttonTextColor() { return this.$store.getters.buttonTextColor},
		image() { return this.$store.getters.imageUrl},
		links() { return this.$store.getters.links }
	},
	components: {
		Links
	}
})
