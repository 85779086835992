import { Getter } from "vuex";
import { IState } from './state';

const title: Getter<IState, IState> = (state) => state.puff.title;
const text: Getter<IState, IState> = (state) => state.puff.text;
const bgColor: Getter<IState, IState> = (state) => state.puff.bgColor;
const textColor: Getter<IState, IState> = (state) => state.puff.textColor;
const buttonColor: Getter<IState, IState> = (state) => state.puff.buttonColor;
const buttonTextColor: Getter<IState, IState> = (state) => state.puff.buttonTextColor;
const imageUrl: Getter<IState, IState> = (state) => state.puff.imageUrl;
const links: Getter<IState, IState> = (state) => state.puff.links;

export default {
	title,
	text,
	bgColor,
	textColor,
	imageUrl,
	buttonColor,
	buttonTextColor,
	links,
}
