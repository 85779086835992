export interface IState {
	puff: IPuff
}

export interface ILink {
	name: string
	uri: string
}

export interface IPuff {
	title: string,
	text: string,
	bgColor: string,
	textColor: string,
	buttonColor: string,
	buttonTextColor: string,
	imageUrl: string,
	isEditMode: boolean,
	hasLinks: boolean,
	links: Array<ILink>
}

const deafultState: IState = {
	puff: {
		title: '',
		text: '',
		bgColor: '',
		textColor: '',
		buttonColor: '',
		buttonTextColor: '',
		imageUrl: '',
		isEditMode: true,
		hasLinks: false,
		links: []
	}
}

export default deafultState;
