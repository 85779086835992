
import Vue from 'vue'
import { ILink } from '../store/state'


interface LinksComputed {
	buttonColor: string
    buttonTextColor: string
    links: Array<ILink>
}

export default Vue.extend<{}, {}, LinksComputed, {}>({
	name: 'Links',
	computed: {
		buttonColor() { return this.$store.getters.buttonColor},
		buttonTextColor() { return this.$store.getters.buttonTextColor},
		links() { return this.$store.getters.links }
	}
})
